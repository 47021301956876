import * as dayjs from 'dayjs'

const date1 = dayjs('2019-06-01');
const date2 = dayjs();

const years = date2.diff(date1, 'year');
const months = date2.diff(date1, 'month') - years * 12;
const days = date2.diff(date1.add(years, 'year').add(months, 'month'), 'day');

const ans = {
  years,
  months: months + 1,
  days,
  allDays: date2.diff(date1, 'day'),
};

const experience = `${ans.years}.${ans.months}`;

const data = {
  "main": {
    "name":"Saboor Elahi",
    "description":`Software Enginner with ${experience}+ years of industry experience with major expertise in full stack web and mobile development`,
    "image":"profilepic.jpeg",
    "bio":`In 2013, I decided to go in the field of Computer and Software industry. It was the same year I completed my 10 years of education. After completing my college, I got admission in BS CS at Fast Nuces. In 2019, I graduated and started job as a software engineer at SSI (Strategic Systems International). Currently, I am working as a Senior Software Engineer there. I have majorly worked in the domain of Web (full-stack) during my ${experience}+ years industry experience so far. Technologies on which I have worked includes: Vue JS, React JS (typescript), React Native, Node JS, SQL database and AWS. Besides web, I like to work on mobile development, data science and explore other areas related to computer science.`,
    "contactmessage":"Here is where you should write your message to readers to have them get in contact with you.",
    "email": "ssaboorelahi@gmail.com",
    "phone": "555-555-5555",
    "github":"https://github.com/nordicgiant2/react-nice-resume",
    "learning": "https://saboorelahi.hashnode.dev/4-years-in-software-industry-unveiling-my-learnings",
    "project":"https://www.linkedin.com/in/saboorelahi/",
    "address":{
      "street":"(Your Street)",
      "city":"Lahore",
      "state":"Pakistan",
      "zip": "54000"
    },
    "website": "https://saboor.dev",
    // "resumedownload":"http://timbakerdev.com",
    "social":[
      // {
      //   "name":"facebook",
      //   "url":"https://github.com/nordicgiant2/react-nice-resume",
      //   "className":"fa fa-facebook"
      // },
      // {
      //   "name":"twitter",
      //   "url":"http://twitter.com",
      //   "className":"fa fa-twitter"
      // },
      {
        "name":"linkedin",
        "url":"https://www.linkedin.com/in/saboorelahi/",
        "className":"fa fa-linkedin"
      },
      // {
      //   "name":"instagram",
      //   "url":"http://instagram.com/tbaker_x",
      //   "className":"fa fa-instagram"
      // },
      {
        "name":"github",
        "url":"https://github.com/saboorelahi",
        "className":"fa fa-github"
      }
    ]
  },
  "resume":{
    "skillmessage":"I have worked mainly on Javascript stack during my industry experience so far but love to explore new languages and technologies. I usually try to write good code and trying to become a better software architect.",
    "education":[
      {
        "school":"Fast Nuces, Lahore",
        "degree":"BS CS",
        "graduated":"May 2019",
        "description":`I took admission in 2015 and graduated in 2019. It was very important phase in 
          my life as I learnt and starting coding from there.`
      }
    ],
    "work":[
      {
        "id": "1",
        "company":"Strategic Systems International",
        "title":"Senior Software Engineer (Dev Lead)",
        "years":"July 2021 - Present",
        "description":`Working on hummingbird application with Tenant (US based startup) and SSI team. The domain of this project revolves around self storage industry. 
        I am responsible for developing new features and resolving bugs in the application / understanding requirements and doing discussion with client representatives as required. Technologies include Vue, Node, MySQL & AWS.
        Currently, I am leading development team there.`
      },
      {
        "id": "2",
        "company":"Strategic Systems International",
        "title":"Software Engineer",
        "years":"June 2019 - June 2021",
        "description":"I started my professional career from there with React JS and eventually moved to full stack web development."
      }
    ],
    "skills":[
      {
        "name":"Backend development",
        "level":"80%"
      },
      {
        "name":"Relational Database",
        "level":"80%"
      },
      {
        "name":"Frontend development",
        "level":"60%"
      },
      {
        "name":"Mobile development",
        "level":"60%"
      }
    ]
  },
  "portfolio":{
    "projects": [
      {
        "title":"Canadian Wanderlust",
        "category":"My Travel Blog for my post-university travels",
        "image":"01.jpg",
        "url":"https://www.canadianwanderlust.com"
      },
      {
        "title":"Fury Fighting Gear",
        "category":"(offline now) A fighting gear company I started",
        "image":"02.jpg",
        "url":"http://www.timbakerdev.com"
      },
      {
        "title":"Original Thai Food",
        "category":"Website I built for a restaurant I like in Thailand",
        "image":"03.jpg",
        "url":"http://www.timbakerdev.com/originalthaifood.github.io"
      },
      {
        "title":"Resume Website",
        "category":"A React based resume website template",
        "image":"04.jpg",
        "url":"http://www.timbakerdev.com"
      },
      {
        "title":"Smirkspace",
        "category":"(MVP Only) A React and Meteor based chat University project.",
        "image":"05.jpg",
        "url":"http://www.smirkspace.com"
      },
      {
        "title":"Canadian Wanderlust",
        "category":"My Travel Blog for my post-university travels",
        "image":"06.jpg",
        "url":"https://www.canadianwanderlust.com"
      },
      {
        "title":"Fury Fighting Gear",
        "category":"(offline now) A fighting gear company I started",
        "image":"01.jpg",
        "url":"http://www.timbakerdev.com"
      },
      {
        "title":"Original Thai Food",
        "category":"Website I built for a restaurant I like in Thailand",
        "image":"02.jpg",
        "url":"http://www.timbakerdev.com/originalthaifood.github.io"
      },
      {
        "title":"Original Thai Food",
        "category":"Website I built for a restaurant I like in Thailand",
        "image":"03.jpg",
        "url":"http://www.timbakerdev.com/originalthaifood.github.io"
      },
      {
        "title":"Resume Website",
        "category":"A React based resume website template",
        "image":"04.jpg",
        "url":"http://www.timbakerdev.com"
      },
      {
        "title":"Smirkspace",
        "category":"(MVP Only) A React and Meteor based chat University project.",
        "image":"05.jpg",
        "url":"http://www.smirkspace.com"
      },
      {
        "title":"Canadian Wanderlust",
        "category":"My Travel Blog for my post-university travels",
        "image":"06.jpg",
        "url":"https://www.canadianwanderlust.com"
      }
    ]
  }
}

export default data;
